/* .container-product {
  display: flex;
  justify-content: center;
  align-items: center;
}

.article-container-product {
  display: flex;
  flex-wrap: wrap;
} */
.article-container-product1 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2px;
}

.container-col {
  /* padding: 0; */
  text-align: left;
  color: black;
}
.label {
  font-weight: 500;
  margin: 0%;
  width: 100%;
  padding: 0;
  font-size: 16px;
}

.input {
  height: 28px;
  padding: 8px;
  margin: 0;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-weight: 200;
  font-size: 16px;
}
.select {
  height: 28px;
  padding: 2px;
  padding-left: 5px;
  margin: 0;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-weight: 200;
  font-size: 16px;
}
.myreact-select {
  margin: 0;
  font-weight: 200;
  font-size: 16px !important;
  flex: 80%;
}
.textarea {
  height: 96px;
  padding: 8px;
  margin: 0;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-weight: 200;
  font-size: 16px;
}
.label-checkbox {
  display: block;
  font-weight: 400 !important;
  margin: 0 0 4px;
  width: auto;
  padding: 0;
  font-size: 15px;
  cursor: pointer;
}
.checkbox {
  position: relative;
  vertical-align: middle;
  bottom: 1px;
  height: 12px;
  width: 12px;
}
.label-imp {
  padding-left: 4px;
  text-align: left;
  color: red;
  font-size: 16px;
}
.label-imp-select {
  flex: 20%;
  padding-left: 4px;
  text-align: left;
  color: red;
  font-size: 16px;
}
.label-imp-select-prd {
  flex: 5%;
  padding-left: 4px;
  text-align: left;
  color: red;
  font-size: 16px;
}
.label-error {
  color: red;
  font-size: 12px;
  padding-left: 4px;
  margin-top: 2px;
  margin-bottom: -20px;
  text-align: left;
}
.label-error-dt {
  color: red;
  font-size: 12px;
  padding-left: 4px;
  margin-top: -6px;
  margin-bottom: -12px;
  text-align: left;
}
.label-error-select {
  color: red;
  font-size: 12px;
  padding-left: 4px;
  text-align: left;
  margin-top: 16px;
  margin-bottom: -26px;
}

.label-error-pos {
  color: red;
  font-size: 11px;
  padding-left: 4px;
  margin-top: 0px;
  margin-bottom: -8px;
  text-align: left;
}

.container-col .input,
.textarea {
  width: 80%;
}

.container-label {
  font-weight: 500 !important;
}

.container-select {
  width: 80% !important;
}

.container-video-div {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.container-video {
  flex: 80%;
  height: 180px;
  width: 80%;
  border-radius: 5px;
}

.container-image {
  flex: 60%;
  height: 128px;
  min-width: 60%;
  object-fit: contain;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.product-image-input {
  width: 100%;
  font-size: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.product-image-label {
  margin-top: 14px;
  font-weight: Bold;
  font-size: 16px;
  justify-content: left;
  align-items: left;
}
.plus-btn {
  flex: 15%;
  font-size: 24px;
  font-weight: 600;
  text-align: left;
  /* margin-left: 6px; */
}
.crs-btn {
  flex: 20%;
  align-self: flex-start;
  font-size: 12px;
  padding-right: 8px;
}
.crs-btn-image {
  flex: 40%;
  align-self: flex-start;
  font-size: 12px;
  padding-left: 8px;
}

.sale-input-div {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.myreact-select-prod {
  margin: 0px;
  font-weight: 200;
  font-size: 14px !important;
  flex: 50%;
}
.myreact-select-prod-qty {
  margin: 0px;
  margin-left: 12px;
  /* margin-right: 12px; */
  height: 103%;
  padding: 10px;
  padding-left: 8px;
  font-weight: 500;
  font-size: 16px !important;
  flex: 7%;
}
.myreact-select-prod-price {
  margin: 0px;
  margin-left: 12px;
  margin-right: 12px;
  height: 103%;
  padding: 10px;
  padding-left: 8px;
  font-weight: 500;
  font-size: 16px !important;
  flex: 7%;
}
.myreact-select-supp {
  margin: 0px;
  /* height: 100%;
  padding: 10px;
  padding-left: 18px; */
  /* margin: 0px; */
  font-weight: 200;
  font-size: 14px !important;
  flex: 15%;
}
.myreact-select-unit {
  margin: 0px;
  margin-left: 12px !important;
  font-weight: 200;
  font-size: 14px !important;
  flex: 10%;
}
.sale-input {
  flex: 89%;
  width: 100%;
  padding: 0px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  /* border-radius: 5px; */
}

.sale-bal {
  border-width: 0px;
  border-radius: 5px;
  flex: 11%;
  align-self: flex-start;
  font-size: 16px;
  padding: 6px;
  padding-left: 8px;
  padding-right: 8px;
  text-align: center;
  color: black;
  font-weight: 500;
}
.custom-button {
  display: inline-block;
  padding: 10px 20px 40px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  border: 2px solid #3498db;
  border-radius: 5px;
  color: #fff;
  background-color: #3498db;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.custom-button:hover {
  background-color: #2980b9;
  border-color: #2980b9;
  color: #fff;
}

.nf-h {
  font-weight: 100;
  margin: 0%;
  width: 100%;
  padding: 0;
  font-size: 14px;
}

/* 
.article-product {
  flex: 0 0 44%;
  padding: 10px;
} */

.article-product-card {
  flex: 0 0 40%;
  padding: 3px;
}

.article-product-btn {
  flex: 0 0 20%;
  padding: 3px;
}

.article-container-cus1 {
  display: flex;
  /* flex-wrap: wrap; */
  /* margin-left: 40%; */
}
.article-cu3 {
  flex: 0 0 80%;
}

.article-cu2 {
  flex: 0 0 20%;
}

/* 
.user-body {
  width: fit-content;
  height: fit-content;
  justify-content: space-around;
} */

.css-13cymwt-control-product {
  width: 100%;
  height: 100%;
}

.article-container-select1 {
  display: flex;
  padding: 0;
  margin: 0;
  justify-content: space-around;
  /* flex-wrap: wrap; */
}

.card-product {
  border: 1px solid #ddd;

  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 1px;
  margin: 10px;
  border-radius: 3px;
  overflow: hidden;
}

.card-product01 {
  border: 1px solid #ddd;

  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* padding: 1px; */
  /* margin: 10px; */
  margin-top: 10px;
  border-radius: 3px;
  overflow: hidden;
}

.card-outerlayout {
  /* border: 1px solid #ddd; */
  background-color: white;
  display: flex;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  /* padding: 1px; */
  /* margin: 10px; */
  /* border-radius: 3px; */
  /* overflow: hidden; */
}

.card-product-keypad {
  border: 2px solid #ddd;
  /* border-radius: 10px; */
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.table-container-product {
  margin-top: 10px;
  max-width: 499%;
  height: 490px;
  overflow-y: auto;
  border-collapse: collapse;
  border: 3px solid #ddd;
  border-radius: 12px;
  /* width: 100%; */
}

.table-container-product1 {
  margin-top: 10px;
  max-width: 1000%;
  overflow-y: auto;
  border-radius: 12px;
  /* width: 100%; */
}

.table-product-tr {
  /* color: #03c9d7; */
  text-align: "center";
  font-size: "20px";
  font-weight: "bold";
}

.centered-input1 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.centered-input1 input {
  width: 80%;
  padding: 8px;
  box-sizing: border-box;
}

.wrapper-product {
  height: 144px;
  display: flex;
  width: 144px;
  /* gap: 20px; */
}

.wrapper-product2 {
  height: 200px;
  display: flex;
  width: 240px;
  /* gap: 20px; */
}

.card-product2 {
  border: 1px solid #ddd;

  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 1px;
  /* margin: 10px; */
  border-radius: 3px;
  overflow: hidden;
}

.product-image-label2 {
  margin-top: 80px;
  font-weight: Bold;
  font-size: 18px;
  justify-content: left;
  align-items: left;
}

.product-image-input2 {
  width: 50%;
  flex-direction: column;
  justify-content: left;
  align-items: left;
}

.product-image-card-layout {
  max-width: 100%;
  max-height: 190px;
}

.card-product-layout {
  border: 1px solid #ddd;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  border-radius: 10px;
  overflow: hidden;
}
